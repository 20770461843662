@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Poppins', Arial, sans-serif;
  line-height: 1.2;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.title-text-style {
  @apply font-500 text-[24px] md:text-[40px] lg:text-[56px] text-textPrimary;
}

.subtitle-text-style {
  @apply  text-textSecondaryGrey text-base md:text-lg lg:text-xl 
}

.pattern-block
{
  @apply pb-6 pt-6 lg:pt-16 px-6 md:px-5 lg:px-10 lg:pb-14 rounded-[20px] md:rounded-[24px] lg:rounded-[50px] gap-3 lg:gap-y-3 lg:gap-x-6 bg-blue border-blueBorder mt-10 lg:mt-20 grid grid-cols-2 md:grid-cols-4;
}

.language-button
{
  @apply flex text-lg text-textPrimary rounded-xl items-center w-full px-3 py-2 gap-2 hover:bg-[#D7E8FF]
}

.custom-toast-container
{
  @apply w-full max-w-[343px] md:max-w-[410px] fixed right-[2%] xl:right-[10%] top-[1%] lg:top-[20%] 
}

.custom-toast-class
{
  @apply bg-[#F0F6FF] p-4 text-textPrimary rounded-lg border border-[#E5EAF6] shadow-lg font-500 font-[Poppins] text-sm md:text-base
}

.custom-toast-body
{
  @apply mr-4 items-start p-0
}
